<template>
  <CRow>
    
    <CCol sm="6" lg="3"  v-for="(project,index) in projectsData" :key="index">
      <CWidgetDropdown :style="{
                    'border-bottom': '3px solid #' + project.theme,
                    'background': '#'+project.theme,
                    'height':'150px'
                  } " :header="project.header" >
        <template #default>
         
            
         
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            
            style="height:10px"
            :data-points="[]"
            point-hover-background-color="primary"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
  
    
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  props: ["projectsData"],
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple }
}
</script>
<style>

</style>